import * as React from 'react';
import styled from 'styled-components';
import { Container, ContainerPage, tablet, mobile } from '../components/container';
import { allGames } from '../games/Games';
import { GameSummary } from '../components/game';
import * as fonts from '../styling/fonts';
import { Helmet } from 'react-helmet';
import { Breadcrumbs } from '../components/breadcrumbs';
import Layout from '../components/layouts';

const DrinkingGames = () => (
    <Layout>
        <Helmet>
            <title>A-Z of Drinking Games | pre-drinks.com</title>
            <meta
                name="description"
                content="A wide range of drinking games for any number of players - good for everything from University socials to student house parties and pre-drinks sessions."
            />
        </Helmet>
        <Breadcrumbs crumbs={[{ url: 'DrinkingGames', name: 'Drinking Games' }]} />
        <Wrapper>
            <ContainerPage>
                <Container>
                    <h1>A-Z of Drinking Games</h1>
                    {allGames.map(g => <GameSummary gameDetails={g} />)}
                </Container>
            </ContainerPage>
        </Wrapper>
    </Layout>
);

const Wrapper = styled.div`
    padding-top: 40px;
    padding-bottom: 30px;

    @media (max-width: ${tablet}px) {
      padding-top: 25px;
    }

    @media (max-width: ${mobile}px) {
      padding-top: 15px;
    }

    h1 {
        font-size: 2em;
        font-family: ${fonts.headerFont};
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 25px;
        opacity: 0.75;
    }
`;

export default DrinkingGames;
